import React, { useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUploader } from "react-drag-drop-files";
import Button from "@mui/material/Button";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { addProduct } from "../../apis/product";
import Spinner from "../../components/spinner";
import MuiAlert from "@mui/material/Alert";
import Modal from "@mui/material/Modal";
import { GrAdd } from "react-icons/gr";
import Configurations from "../../components/section/configurations";
import { useNavigate, useParams } from "react-router-dom";
import useReadUpdated from "../../hooks/updated/useRead";
import ProductDetails from "../../components/elements/Editor/ProductDetails";
import { TextField } from "@mui/material";
import useCreate from "../../hooks/useCreate";
import { addMusic } from "../../apis/music";
import useAddMusic from "../../hooks/music/useAddMusic";
import useMoodListing from "../../hooks/music/useMoodListing";
import useUpload from "../../hooks/useUpload";
import useEditMusic from "../../hooks/music/useEditMusic";

const fileTypes = ["JPG", "PNG", "JPEG"];
const audioFileTypes = ["mp3", "wav"];
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MusicFormDoNothing() {
  const navigate = useNavigate();
  const { musicId } = useParams();
  const isEditMode = Boolean(musicId);
  const productImageUpload = useRef(null);
  const queryClient = useQueryClient();
  const initialState = {
    audioName: "",
    description: "",
    audioId: "",
    avatarId: "",
    audioCategory: [],
    audioPreview: null,
    avatarPreview: null,
  };
  const { fetchSingleMusic, updateMusic, handleEditForm } = useEditMusic();
  const [productData, setProductData] = useState(initialState);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { handleUpload: uploadAvatar } = useUpload({
    refreshUrl: "music/audio_listing",
    url: "upload/upload_media",
    onSuccess: (response) => {
      const { media_id, media_url } = response.data;
      if (media_id && media_url) {
        setProductData((prev) => ({
          ...prev,
          avatarId: media_id,
          avatarPreview: media_url,
        }));
      } else {
        console.error("Invalid response data:", response);
      }
    },
    onError: (error) => {
      console.error("Upload Error:", error);
      setIsError(true);
    },
  });
  const handleAvatarUpload = (file) => {
    uploadAvatar(file, "audio/avatar");
  };
  const handleAudioUpload = (file) => {
    uploadAudio(file, "audio");
  };
  const { handleUpload: uploadAudio } = useUpload({
    refreshUrl: "music/audio_listing",
    url: "upload/upload_media",
    onSuccess: (response) => {
      const { media_id, media_url } = response.data;
      if (media_id && media_url) {
        setProductData((prev) => ({
          ...prev,
          audioId: media_id,
          audioPreview: media_url,
        }));
      } else {
        console.error("Invalid response data:", response);
      }
    },
    onError: (error) => {
      console.error("Upload Error:", error);
      setIsError(true);
    },
  });

  const postProductDataToServer = useMutation((data) => addMusic(data), {
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries("productList");
        setIsSuccess(true);
        setTimeout(() => {
          handleCloseAndReset();
        }, 2000);
      }
    },
    onError: () => {
      setIsError(true);
    },
  });
  const closeErrorMessage = () => {
    setIsError(false);
  };

  useEffect(() => {
    if (musicId) {
      fetchSingleMusic(musicId)
        .then((data) => {
          console.log(data?.data.audioCategory);

          setProductData({
            musicId,
            audioName: data?.data?.audioName || "",
            description: data?.data?.description || "",
            audioId: data?.data?.audioId || "",
            avatarId: data?.data?.avatarId || "",
            audioCategory:
              data?.data?.audioCategory.map((item) => item.moodId) || [],
            audioPreview: data?.data?.audioUrl || null,
            avatarPreview: data?.data?.avatarUrl || null,
          });
        })
        .catch(() => setIsError(true));
    }
  }, [musicId, fetchSingleMusic]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditMode) {
      updateMusic(musicId, productData)
        .then((response) => {
          if (response.status === 200) {
            setIsSuccess(true);
            setTimeout(() => {
              handleCloseAndReset();
            }, 2000);
          }
        })
        .catch(() => setIsError(true));
    } else {
      postProductDataToServer.mutate(productData, {
        onSuccess: (data) => {
          if (data.status === 200) {
            setIsSuccess(true);
            setTimeout(() => {
              handleCloseAndReset();
            }, 2000);
          }
        },
        onError: () => {
          setIsError(true);
        },
      });
    }
  };

  const handleCloseAndReset = () => {
    setProductData({
      audioId: "",
      audioPreview: "",
      audioName: "",
      description: "",
      avatarId: "",
      avatarPreview: null,
    });
    setIsError(false);
    setIsSuccess(false);
  };

  const handleAddFromData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProductData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const { queryData } = useMoodListing();

  const moodlist = queryData?.data?.data;
  const handleMoodSelection = (moodId, isChecked) => {
    setProductData((prev) => {
      let updatedAudioCategory;
      if (isChecked) {
        // Add selected moodId as an object
        updatedAudioCategory = [...prev.audioCategory, { moodId }];
      } else {
        // Remove the unselected moodId
        updatedAudioCategory = prev.audioCategory.filter(
          (item) => item.moodId !== moodId
        );
      }
      return {
        ...prev,
        audioCategory: updatedAudioCategory,
      };
    });
  };

  const { handleAddMusic } = useAddMusic();

  const handleSubmitForm = (e) => {
    e.preventDefault();
    if (musicId) {
      handleEditForm(productData);
    } else {
      handleAddMusic(e, productData);
    }
  };

  return (
    <>
      <div className="container_xxl bg-white">
        <div className="flex items-center space-x-4 headingBorder__b sticky top-0 z-40 bg-white">
          <div>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div>
            <h1 className="heading">
              {isEditMode ? "Edit Music" : "Add Music"}
            </h1>
          </div>
        </div>
        <div className="form-body py-8">
          <form className="" autoComplete="off" onSubmit={handleSubmitForm}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="Mood Name" className="label">
                  Audio Name <small className="text-red-700">*</small>
                </label>
                <TextField
                  required
                  type="text"
                  placeholder="Audio Name"
                  name="audioName"
                  className="input"
                  value={productData.audioName}
                  onChange={handleAddFromData}
                />
              </div>
              
              <div>
                <label className="label">Audio Category</label>
                <div className="space-x-4 pt-2">
                  {moodlist?.map((mood, idx) => {
                    return (
                      <label
                        key={mood.moodId} // Use moodId as key for better uniqueness
                        className="border border-gray-300 rounded p-2 space-x-2 cursor-pointer select-none"
                      >
                        <input
                          type="checkbox"
                          name="audioCategory"
                          data-id={mood.moodId}
                          // value={mood?.moodId}
                          checked={productData?.audioCategory.includes(
                            mood.moodId
                          )}
                          onChange={(e) =>
                            handleMoodSelection(mood.moodId, e.target.checked)
                          }
                        />
                        <span className="capitalize">{mood.name}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
              <div className="col-span-2">
                <label htmlFor="Mood Name" className="label">
                  Audio Description <small className="text-red-700">*</small>
                </label>
                <TextField
                  required
                  type="text"
                  placeholder="Audio Description"
                  name="description"
                  className="input"
                  value={productData.description}
                  onChange={handleAddFromData}
                />
              </div>
              <div>
                <div className="my-7 flex items-center justify-between border-b border-gray-400">
                  <div className="pb-3">
                    <h1 className="font-semibold text-2xl">Avatar</h1>
                    <small className="text-red-700">
                      Note: Image to be less than 1mb
                    </small>
                  </div>
                </div>
                <div className=" space-y-4">
                  {!productData.avatarPreview ? (
                    <FileUploader
                      name="avatarPreview"
                      types={fileTypes}
                      handleChange={handleAvatarUpload}
                      classes="file-uploader"
                      hoverTitle="Drop here"
                    />
                  ) : (
                    <div className="border border-dashed border-blue-700 rounded">
                      <div className="w-40 mx-auto py-4 text-center space-y-3">
                        <img src={productData.avatarPreview} alt="preview" />
                        <Button
                          variant="outlined"
                          onClick={() =>
                            setProductData((prev) => ({
                              ...prev,
                              avatarId: "",
                              avatarPreview: null,
                            }))
                          }
                        >
                          Re-Upload
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="my-7 flex items-center justify-between border-b border-gray-400">
                  <div className="pb-3">
                    <h1 className="font-semibold text-2xl">Audio File</h1>
                    <small className="text-red-700">
                      Note: File to be less than 10mb
                    </small>
                  </div>
                </div>
                <div className=" space-y-4">
                  {!productData.audioPreview ? (
                    <FileUploader
                      name="audioFile"
                      types={audioFileTypes}
                      handleChange={handleAudioUpload}
                      classes="file-uploader"
                      hoverTitle="Drop here"
                    />
                  ) : (
                    <div className="border border-dashed border-blue-700 rounded">
                      <div className="w-40 mx-auto py-4 text-center space-y-3">
                        <audio controls src={productData.audioPreview}>
                          Your browser does not support the audio element.
                        </audio>
                        <Button
                          variant="outlined"
                          onClick={() =>
                            setProductData((prev) => ({
                              ...prev,
                              audioId: "",
                              audioPreview: null,
                            }))
                          }
                        >
                          Re-Upload
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="text-center my-6">
              {postProductDataToServer.isLoading ? (
                <Spinner />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  className="primaryBtn-contained"
                >
                  {isEditMode ? "Save Changes" : "Save Music"}
                </Button>
              )}
            </div>
          </form>
        </div>
        {isError && (
          <Alert
            onClose={closeErrorMessage}
            className="mb-4"
            severity="error"
            sx={{ width: "100%" }}
          >
            {postProductDataToServer?.error?.message}, Please try again later
          </Alert>
        )}
        {isSuccess && (
          <Alert
            onClose={closeErrorMessage}
            className="mb-4"
            severity="success"
            sx={{ width: "100%" }}
          >
            Music Added Succesfully
          </Alert>
        )}
      </div>
    </>
  );
}

export default MusicFormDoNothing;
